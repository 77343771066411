"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('slick-carousel');
function InitTemplateA() {
    var toggleProductInfo = function () {
        jQuery('#toggle-product-info').click(function () {
            jQuery('#product-info').toggleClass('closed');
            jQuery('#toggle-product-info').toggleClass('open');
        });
    };
    var onFormSubmitClick = function () {
        jQuery('.FormSubmitButton').click(function () {
            setTimeout(function () {
                if (jQuery('.Form__Element.ValidationFail').length === 0) {
                    jQuery('body').addClass('form-submitted');
                    if (jQuery('#form-submitted-anchor').length) {
                        var formSubmittedAnchorTop = jQuery('#form-submitted-anchor').position().top;
                        jQuery(window).scrollTop(formSubmittedAnchorTop - 28);
                    }
                }
            }, 500);
        });
    };
    window.heroSliderShowNext = function () {
        jQuery('.o-hero-container .o-slider-content-frontpage .o-button').click(function (e) {
            var parent = jQuery('.o-hero-container');
            var slideAmount = jQuery('.o-hero-container .slick-slide').length;
            var currentSlideIdx = eval(jQuery('.o-hero-container .slick-slide.slick-current').data('slick-index'));
            var nextSlideIdx = (currentSlideIdx === slideAmount) ? 0 : currentSlideIdx + 1;
            window.setSlide(nextSlideIdx, parent);
            e.preventDefault();
        });
    };
    window.scrollHelper = function (triggerElem, targetElem) {
        jQuery(triggerElem).click(function (e) {
            e.preventDefault();
            var topPos = jQuery(targetElem).position().top;
            jQuery("html, body").animate({
                scrollTop: topPos
            });
        });
    };
    var setDocumentVisible = function () {
        jQuery('.o-hero-container, .o-container, .site-navigation, .row, .hero-image').css("visibility", "visible");
        // Loader elements
        if (jQuery('.loader-img').length && jQuery('.loader-layer').length) {
            jQuery('.loader-img, .loader-layer').fadeOut("fast");
        }
    };
    jQuery(document).ready(function () {
        toggleProductInfo();
        onFormSubmitClick();
        //heroSliderShowNext();
        setDocumentVisible();
    });
}
exports.InitTemplateA = InitTemplateA;
