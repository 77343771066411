"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('./../scss/sites/template-a/template-a.scss');
/*import { Init, InitReact, ImportCoreweb } from './../js/index';*/
var index_1 = require("./../js/index");
var template_a_1 = require("./Sites/template-a");
// Initialize scripts that are used in all websites
index_1.Init();
// Import site specific scripts
template_a_1.InitTemplateA();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
/*InitReact();*/ 
